@keyframes breathing {
    0% {
      transform: scale(1);
      box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.7);
    }
    50% {
      transform: scale(1.2);
      box-shadow: 0 0 20px 4px rgba(255, 0, 0, 0.9);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.7);
    }
  }
  
  .breathing-dot {
    animation: breathing 2s infinite;
  }
  
  .popup-one {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    top: 170px;
    left: 170px;
    z-index: 0;
    padding: 10px;
    border-radius: 5px;
    animation: slideRight 0.5s forwards, fadeIn 0.5s forwards;
  }

  .popup-two {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    top: 60px;
    left: 1070px;
    z-index: 0;
    padding: 10px;
    border-radius: 5px;
    animation: slideLeft 0.5s forwards, fadeIn 0.5s forwards;
  }

  .popup-three {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    top: 510px;
    left: 130px;
    z-index: 0;
    padding: 10px;
    border-radius: 5px;
    animation: slideRight 0.5s forwards, fadeIn 0.5s forwards;
  }

  .popup-four {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    top: 570px;
    left: 1030px;
    z-index: 0;
    padding: 10px;
    border-radius: 5px;
    animation: slideLeft 0.5s forwards, fadeIn 0.5s forwards;
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideLeft {
    from {
      transform: translateX(50%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  
  /* @keyframes slideLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .popup-one.exit {
    animation: slideLeft 0.5s forwards, fadeOut 0.5s forwards;
  }

  .popup-three.exit {
    animation: slideLeft 0.5s forwards, fadeOut 0.5s forwards;
  } */
  
  
  
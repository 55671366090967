@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MmcOffice";
  src: url("/src/assets/mmksifont/MmcOfficeRegular.ttf") format("truetype");
}

@font-face {
  font-family: "MmcOfficeBold";
  src: url("/src/assets/mmksifont/MmcOfficeBold.ttf") format("truetype");
}

@keyframes breath {
  0% {
    filter: drop-shadow(10px 10px 10px rgba(255, 0, 0, 0.6));
  }
  50% {
    filter: drop-shadow(20px 15px 15px rgba(255, 0, 0, 0.3));
  }
  100% {
    filter: drop-shadow(10px 10px 10px rgba(255, 0, 0, 0.6));
  }
}

.btnimage {
  z-index: 0;
  filter: drop-shadow(10px 10px 10px rgba(255, 0, 0, 0.6));
  animation: breath 1.5s infinite;
}
